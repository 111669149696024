import 'index.css';
import { Application } from '@hotwired/stimulus';
import controllers from './controllers/**/*.{js,js.rb}';

// Import all JavaScript & CSS files from src/_components
import components from 'bridgetownComponents/**/*.{js,jsx,js.rb,css}';

window.Stimulus = Application.start();

Object.entries(controllers).forEach(([filename, controller]) => {
  if (filename.includes('_controller.') || filename.includes('-controller.')) {
    const identifier = filename
      .replace('./controllers/', '')
      .replace(/[_-]controller..*$/, '')
      .replace('_', '-')
      .replace('/', '--');

    Stimulus.register(identifier, controller.default);
  }
});
