export const getClosestAncestor = (startEl, targetEl) => {
  let element = false;

  const traverse = function (parent) {
    if (parent.isEqualNode(targetEl)) {
      return parent;
    }
    if (parent.isEqualNode(document.body)) {
      return document.body;
    }
    return traverse(parent.parentNode);
  };

  if (isElement(targetEl)) {
    element = startEl.isEqualNode(targetEl)
      ? startEl
      : traverse(startEl.parentNode);
  } else {
    if (Array.isArray(targetEl)) {
      targetEl.forEach((item) => {
        if (item.contains(startEl)) {
          return (element = item);
        }
      });
    } else if (typeof targetEl === 'object') {
      for (let key in targetEl) {
        let node = targetEl[key];
        if (node.contains(startEl)) {
          element = node;
        }
      }
    }
  }
  return element;
};

export const getScrollTop = (target) => {
  let targetY = null;
  let targetElement = null;

  if (isElement(target)) {
    targetElement = target;
  } else if (typeof target === 'string') {
    if (target.includes('#')) {
      let queryId = target.replace('#', '');
      targetElement = document.getElementById(queryId);
    }

    if (!targetElement) {
      return console.error(`No element exists with ID ${target}.`);
    }
  } else {
    return console.error('`target` must be an element or ID string.');
  }

  targetY =
    targetElement.getBoundingClientRect().top +
    (document.documentElement.scrollTop || window.scrollY);
  return targetY;
};

export const isElement = (o) => {
  if (typeof HTMLElement === 'object') {
    return o instanceof HTMLElement;
  } else if (
    o &&
    typeof (o === 'object') &&
    o !== null &&
    o.nodeType === 1 &&
    typeof (o.nodeName === 'string')
  ) {
    return true;
  }
};

export const smoothScroll = (target, offset = 0) => {
  let targetY = null;

  if (typeof target === 'string') {
    if (target.includes('#')) {
      // Query element using ID string and gets its scroll position
      targetY = getScrollTop(target);
    } else {
      // Scroll up or down by value relative to the current scroll position
      if (target.includes('=')) {
        if (target.includes('+')) {
          targetY =
            (document.documentElement.scrollTop || window.scrollY) +
            parseInt(target.replace('+=', ''));
        } else if (target.includes('-')) {
          targetY =
            (document.documentElement.scrollTop || window.scrollY) -
            parseInt(target.replace('-=', ''));
        } else {
          console.error(
            'Relative value must be formatted as `+={number}` or `-={number}.`'
          );
        }
      }
    }
    // Scroll to specified value
  } else if (typeof target === 'number') {
    if (target % 1 === 0) {
      if (target >= 0) {
        targetY = target;
      } else {
        console.error('Target scroll position must be a positive integer.');
      }
    }
  }

  if (!targetY) return;

  scroll({ behavior: 'smooth', top: targetY + offset });
};

export default {
  getScrollTop,
  isElement,
  smoothScroll
};
