import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['link'];
  static values = {
    mobileMenuOpen: {
      type: Boolean,
      default: false
    },
    scrolled: {
      type: Boolean,
      default: false
    }
  };

  connect() {
    this.toggleScrolledState();
  }

  closeMobileMenu(_event) {
    document.body.classList.remove('scroll-lock');
    this.mobileMenuOpenValue = false;
  }

  openMobileMenu(_event) {
    document.body.classList.add('scroll-lock');
    this.mobileMenuOpenValue = true;
  }

  toggleMobileMenu(_event) {
    this.mobileMenuOpenValue
      ? this.closeMobileMenu(false)
      : this.openMobileMenu();
  }

  toggleScrolledState(_event) {
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (scrollTop > 0) {
      this.scrolledValue = true;
    } else {
      this.scrolledValue = false;
    }
  }
}
