import { Controller } from '@hotwired/stimulus';
import { smoothScroll } from '../utilities';

export default class extends Controller {
  static targets = ['nav'];

  connect() {
    this.scrollOffset = this.navTarget.getBoundingClientRect().height * -1;

    this.setupFaviconSwitcher();
    this.enableSiteTransitions();

    Array.from(document.getElementsByTagName('a')).forEach((a) => {
      a.addEventListener('click', this.scrollToHash);
    });
  }

  disableSiteTransitions = () => {
    let resizeTimer;

    document.body.classList.add('no-site-transitions');
    clearTimeout(resizeTimer);

    resizeTimer = setTimeout(() => {
      this.element.classList.remove('no-site-transitions');
    }, 250);
  };

  enableSiteTransitions = () => {
    document.body.classList.remove('no-site-transitions');
  };

  scrollToHash = (event) => {
    if (event.type === 'load' && window.location.hash) {
      event.preventDefault();
      smoothScroll(window.location.hash, this.scrollOffset);
      window.removeEventListener('load', this.scrollToHash);
    } else if (event.type === 'click') {
      let url = new URL(event.target.href);
      if (!url.hash) return;

      if (url.pathname === window.location.pathname) {
        event.preventDefault();
        history.pushState({}, '', url.hash);
        smoothScroll(url.hash, this.scrollOffset);
      } else {
        window.location.href = url.href;
      }
    }
  };

  setupFaviconSwitcher = () => {
    this.matcher = window.matchMedia('(prefers-color-scheme: dark)');
    this.lightModeIcon = document.querySelector('link#favicon-light');
    this.darkModeIcon = document.querySelector('link#favicon-dark');
    this.matcher.addEventListener('change', this.switchFavicon);
    this.switchFavicon();
  };

  switchFavicon = () => {
    if (this.matcher.matches) {
      this.lightModeIcon.remove();
      document.head.append(this.darkModeIcon);
    } else {
      document.head.append(this.lightModeIcon);
      this.darkModeIcon.remove();
    }
  };
}
