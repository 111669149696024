import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['label'];

  connect = () => {
    this.fontSize = parseInt(
      getComputedStyle(this.element)
        .getPropertyValue('font-size')
        .replace('px', '')
    );
  };

  toggleLabelVisibility = (event) => {
    if (event.target.scrollTop > this.fontSize / 3) {
      this.labelTarget.classList.add('opacity-0');
    } else {
      this.labelTarget.classList.remove('opacity-0');
    }
  };
}
