import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['message'];

  connect() {
    this.form = this.element.tagName == 'FORM' ? this.element : this.formTarget;
  }

  createErrorMessage = () => {
    this.messageTarget.classList.remove('success');
    this.messageTarget.classList.add('error');
    this.messageTarget.textContent =
      'Oops! Something went wrong. Please try submitting again.';
    this.showMessage();
  };

  createSuccessMessage = () => {
    this.messageTarget.classList.remove('error');
    this.messageTarget.classList.add('success');
    this.messageTarget.textContent = 'Form submitted successfully! Thank you.';
    this.showMessage();
  };

  showMessage = () => {
    clearTimeout(this.messageTimer);
    this.messageTarget.setAttribute('data-hidden', 'false');

    this.messageTimer = setTimeout(() => {
      this.messageTarget.setAttribute('data-hidden', 'true');
    }, 8000);
  };

  submit = (event) => {
    event.preventDefault();

    let formData = new FormData(this.form);

    fetch(this.data.get('url'), {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString()
    })
      .then(() => {
        this.form.reset();
        this.createSuccessMessage();
      })
      .catch((error) => {
        console.error(error);
        this.createErrorMessage(error);
      });
  };
}
